































import coreApiClient from "@/services/apis/coreApiClient";
import defaultComponent from "@vuetifyx/utils/defaultComponent";
import ProductOrCombo from "../partials/ProductOrCombo.vue";

export default defaultComponent.apply({
  components: {
    ProductOrCombo,
  },
  props: ["value", "market"],
  data() {
    return {
      tableOptions: null,
      products: {},
      productCounts: {},
    };
  },
  computed: {
    warehouseId() {
      if (!this.context) {
        return;
      }
      const context = this.context();
      if (!context) {
        return;
      }
      const contextContext = context.context();
      if (!contextContext) {
        return;
      }
      return contextContext.inputs && contextContext.inputs.warehouseId.value;
    },
    valid() {
      if (!this.warehouseId) {
        return;
      }
      for (const item of this.value) {
        if (!this.itemValid(item)) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    async initialize() {
      const productIds = [];
      for (const i in this.value) {
        const item = this.value[i];
        for (const productItem of item.productItems) {
          const id = productItem.product._id;
          productIds.push(id);
          if (!this.productCounts[id]) {
            this.productCounts[id] = 0;
          }
          this.productCounts[id] += productItem.count || 0;
        }
      }
      const { items } = await coreApiClient.call("products", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          filters: [
            {
              key: "_id",
              operator: "in",
              value: [...new Set(productIds)],
            },
          ],
        }),
      });
      for (const item of items) {
        this.products[item._id] = item;
      }

      const form = this.context().context();
      form.inputs.warehouseId.onUpdated(() => {
        if (this.valid !== undefined) {
          this.$emit("validated", this.valid);
        }
      });

      this.tableOptions = this.makeTableOptions(this.value, this.market);
    },
    itemValid(item) {
      for (const productItem of item.productItems) {
        const productId = productItem.product._id;
        const productCount = this.productCounts[productId];
        const warehouseProductCount = this.warehouseCount(productId);
        if (warehouseProductCount < productCount) {
          return false;
        }
      }
      return true;
    },
    warehouseCount(productId) {
      const product = this.products[productId];
      if (product) {
        const countStatus = product.countStatuses.find((item) => item.warehouseId === this.warehouseId);
        if (countStatus) {
          return countStatus.count;
        }
      }
      return 0;
    },
    makeTableOptions(items, market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          topActionButtons: {
            showDisplaySetting: {
              ext: {
                hidden: true,
              },
            },
          },
          displayFields: {
            orderId: {
              text: "ID",
              sortable: true,
            },
            productOrCombo: {
              text: "Sản phẩm",
            },
            count: {
              text: "Số lượng",
              sortable: true,
            },
            productTotalCount: {
              text: "SL xuất kho / SL trong kho",
              sortable: true,
            },
            singlePrice: {
              text: "Đơn giá",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            totalPrice: {
              text: "Tổng giá",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value * item.count;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            // customerInfo: {
            //   text: "TT Khách hàng",
            //   sortable: true,
            // },
          },
          search: {
            ext: {
              hidden: true,
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll() {
              return [items, items.length];
            },
          },
        },
      };
    },
  },
});
