var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.valid === false)?_c('div',{staticClass:"mb-4 red--text font-weight-bold"},[_vm._v("Không đủ số lượng hàng")]):_vm._e(),(_vm.tableOptions)?_c('x-default-data-table',{attrs:{"x-options":_vm.tableOptions,"x-context":_vm.self},scopedSlots:_vm._u([{key:"item.productOrCombo",fn:function(ref){
var item = ref.item;
return [_c('product-or-combo',{attrs:{"item":item}})]}},{key:"item.customerInfo",fn:function(ref){
var item = ref.item;
return [(item.customerName)?_c('div',[_vm._v("NAME: "+_vm._s(item.customerName))]):_vm._e(),(item.customerEmail)?_c('div',[_vm._v("EMAIL: "+_vm._s(item.customerEmail))]):_vm._e(),(item.customerPhone)?_c('div',[_vm._v("PHONE: "+_vm._s(item.customerPhone))]):_vm._e(),(item.customerMessage)?_c('div',[_vm._v("MESSAGE: "+_vm._s(item.customerMessage))]):_vm._e()]}},{key:"item.productTotalCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",class:{
          'red--text': !_vm.itemValid(item),
          'green--text': _vm.itemValid(item),
        }},_vm._l((item.productItems),function(productItem){return _c('div',{key:productItem.product._id},[_vm._v(" "+_vm._s(productItem.product.sku)+": "+_vm._s(_vm.productCounts[productItem.product._id])+" / "+_vm._s(_vm.warehouseCount(productItem.product._id))+" ")])}),0)]}}],null,false,1750734797)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }